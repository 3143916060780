import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const UserPrivilege = () => import('@/entities/user-privilege/user-privilege.vue');
const UserPrivilegeUpdate = () => import('@/entities/user-privilege/user-privilege-update.vue');
const UserPrivilegeDetails = () => import('@/entities/user-privilege/user-privilege-details.vue');

const Project = () => import('@/entities/project/project.vue');
const ProjectUpdate = () => import('@/entities/project/project-update.vue');
const ProjectDetails = () => import('@/entities/project/project-details.vue');

const Province = () => import('@/entities/province/province.vue');
const ProvinceUpdate = () => import('@/entities/province/province-update.vue');
const ProvinceDetails = () => import('@/entities/province/province-details.vue');

const Permission = () => import('@/entities/permission/permission.vue');
const PermissionUpdate = () => import('@/entities/permission/permission-update.vue');
const PermissionDetails = () => import('@/entities/permission/permission-details.vue');

const FileGeodetic = () => import('@/entities/file-geodetic/file-geodetic.vue');
const FileGeodeticUpdate = () => import('@/entities/file-geodetic/file-geodetic-update.vue');
const FileGeodeticDetails = () => import('@/entities/file-geodetic/file-geodetic-details.vue');

const Map2d = () => import('@/entities/map-2-d/map-2-d.vue');
const Map2dUpdate = () => import('@/entities/map-2-d/map-2-d-update.vue');
const Map2dDetails = () => import('@/entities/map-2-d/map-2-d-details.vue');

const Vr360 = () => import('@/entities/vr-360/vr-360.vue');
const Vr360Update = () => import('@/entities/vr-360/vr-360-update.vue');
const Vr360Details = () => import('@/entities/vr-360/vr-360-details.vue');

const Model3d = () => import('@/entities/model-3-d/model-3-d.vue');
const Model3dUpdate = () => import('@/entities/model-3-d/model-3-d-update.vue');
const Model3dDetails = () => import('@/entities/model-3-d/model-3-d-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'user-privilege',
      name: 'UserPrivilege',
      component: UserPrivilege,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-privilege/new',
      name: 'UserPrivilegeCreate',
      component: UserPrivilegeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-privilege/:userPrivilegeId/edit',
      name: 'UserPrivilegeEdit',
      component: UserPrivilegeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-privilege/:userPrivilegeId/view',
      name: 'UserPrivilegeView',
      component: UserPrivilegeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project',
      name: 'Project',
      component: Project,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/new',
      name: 'ProjectCreate',
      component: ProjectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/:projectId/edit',
      name: 'ProjectEdit',
      component: ProjectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/:projectId/view',
      name: 'ProjectView',
      component: ProjectDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'province',
      name: 'Province',
      component: Province,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'province/new',
      name: 'ProvinceCreate',
      component: ProvinceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'province/:provinceId/edit',
      name: 'ProvinceEdit',
      component: ProvinceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'province/:provinceId/view',
      name: 'ProvinceView',
      component: ProvinceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission',
      name: 'Permission',
      component: Permission,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission/new',
      name: 'PermissionCreate',
      component: PermissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission/:permissionId/edit',
      name: 'PermissionEdit',
      component: PermissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission/:permissionId/view',
      name: 'PermissionView',
      component: PermissionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-geodetic',
      name: 'FileGeodetic',
      component: FileGeodetic,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-geodetic/new',
      name: 'FileGeodeticCreate',
      component: FileGeodeticUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-geodetic/:fileGeodeticId/edit',
      name: 'FileGeodeticEdit',
      component: FileGeodeticUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-geodetic/:fileGeodeticId/view',
      name: 'FileGeodeticView',
      component: FileGeodeticDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'map-2-d',
      name: 'Map2d',
      component: Map2d,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'map-2-d/new',
      name: 'Map2dCreate',
      component: Map2dUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'map-2-d/:map2dId/edit',
      name: 'Map2dEdit',
      component: Map2dUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'map-2-d/:map2dId/view',
      name: 'Map2dView',
      component: Map2dDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'vr-360',
      name: 'Vr360',
      component: Vr360,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'vr-360/new',
      name: 'Vr360Create',
      component: Vr360Update,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'vr-360/:vr360Id/edit',
      name: 'Vr360Edit',
      component: Vr360Update,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'vr-360/:vr360Id/view',
      name: 'Vr360View',
      component: Vr360Details,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-3-d',
      name: 'Model3d',
      component: Model3d,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-3-d/new',
      name: 'Model3dCreate',
      component: Model3dUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-3-d/:model3dId/edit',
      name: 'Model3dEdit',
      component: Model3dUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-3-d/:model3dId/view',
      name: 'Model3dView',
      component: Model3dDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
